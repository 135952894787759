import {HTMLProps} from "react";
import classNames from "classnames";

interface HeadingProps extends HTMLProps<HTMLHeadingElement> {
  headerType?: number,
  scrollRef?: any,
}

export function Heading({headerType, scrollRef, ...props}: HeadingProps) {
  const className = props.className ?? "";
  delete(props.className);
  return (
    <>
      {(headerType === 1) && <h1 className={className} ref={scrollRef} {...props}>{props.children}</h1>}
      {(headerType === 2 || !headerType) && <h2 className={className} ref={scrollRef} {...props}>{props.children}</h2>}
      {headerType === 3 && <h3 className={className} ref={scrollRef} {...props}>{props.children}</h3>}
      {headerType === 4 && <h4 className={className} ref={scrollRef} {...props}>{props.children}</h4>}
      {headerType === 5 && <h5 className={className} ref={scrollRef} {...props}>{props.children}</h5>}
      {headerType === 6 && <h6 className={className} ref={scrollRef} {...props}>{props.children}</h6>}
    </>
  )
}